body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: 'Orbitron', sans-serif;
}

h1 {
  color: #39ff14; /* Neon green */
  font-size: 3rem;
  text-shadow: 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14;
  text-align: center;
}